// Global
export const SET_LOADING = 'SET_LOADING'

// Auth
export const SET_AUTH_USER = 'SET_AUTH_USER'

// User
export const FETCH_USER_ASYNC = 'FETCH_USER_ASYNC'
export const SIGN_OUT_USER = 'SIGN_OUT_USER'

export const SET_USER = 'SET_USER'
export const SET_USER_PREFERRED_THEME = 'SET_USER_PREFERRED_THEME'

// Themeing and display
export const SET_THEME = 'SET_THEME';
export const TOGGLE_DISPLAY_MODE = 'TOGGLE_DISPLAY_MODE';

// Documents
export const FETCH_DOCUMENTS_ASYNC = 'FETCH_DOCUMENTS_ASYNC';
export const SET_DOCUMENTS = 'SET_DOCUMENTS';
export const SAVE_DOCUMENTS = 'SAVE_DOCUMENTS';
export const PREPEND_DOCUMENT = 'PREPEND_DOCUMENT';
export const PREPEND_DOCUMENT_AND_SAVE = 'PREPEND_DOCUMENT_AND_SAVE';
export const REMOVE_DOCUMENT = 'REMOVE_DOCUMENT';
export const REMOVE_DOCUMENT_AND_SAVE = 'REMOVE_DOCUMENT_AND_SAVE';
export const UPDATE_DOCUMENT = 'UPDATE_DOCUMENT';
export const UPDATE_DOCUMENT_AND_SAVE = 'UPDATE_DOCUMENT_AND_SAVE';
export const UPDATE_DOCUMENT_NOT_TIMESTAMP = 'UPDATE_DOCUMENT_NOT_TIMESTAMP';
export const UPDATE_DOCUMENT_NOT_TIMESTAMP_AND_SAVE = 'UPDATE_DOCUMENT_NOT_TIMESTAMP_AND_SAVE';
export const SET_ACTIVE_DOCUMENT = 'SET_ACTIVE_DOCUMENT';
export const SET_DOCUMENT_FILTER_TERM = 'SET_DOCUMENT_FILTER_TERM';
export const SET_DOCUMENT_FILTER_TAG = 'SET_DOCUMENT_FILTER_TAG';
export const SET_DOCUMENT_FILTER_TAG_DROP_DOWN = 'SET_DOCUMENT_FILTER_TAG_DROP_DOWN';

// Editor
export const EDITOR_SAVE_DOCUMENT_ASYNC = 'EDITOR_SAVE_DOCUMENT_ASYNC';
export const EDITOR_SAVE_AND_CLOSE_DOCUMENT = 'EDITOR_SAVE_AND_CLOSE_DOCUMENT';
export const EDITOR_OPEN_DOCUMENT = 'EDITOR_OPEN_DOCUMENT';
export const EDITOR_LOAD_DOCUMENT_AND_OPEN_EDITOR = 'EDITOR_LOAD_DOCUMENT_AND_OPEN_EDITOR';
export const EDITOR_CREATE_DOCUMENT = 'EDITOR_CREATE_DOCUMENT';
export const EDITOR_CLOSE_DOCUMENT = 'EDITOR_CLOSE_DOCUMENT';
export const EDITOR_SET_SAVE_PENDING = 'EDITOR_SET_SAVE_PENDING';
export const EDITOR_SET_ACTIVE_DOCUMENT_ID = 'EDITOR_SET_ACTIVE_DOCUMENT_ID';
export const EDITOR_SET_ACTIVE_DOCUMENT_TITLE = 'EDITOR_SET_ACTIVE_DOCUMENT_TITLE';
export const EDITOR_SET_ACTIVE_DOCUMENT_VALUE = 'EDITOR_SET_ACTIVE_DOCUMENT_VALUE';

export const EDITOR_SET_SHORTCUT_TARGET = 'EDITOR_SET_SHORTCUT_TARGET';
export const EDITOR_SET_SHORTCUT_SEARCH = 'EDITOR_SET_SHORTCUT_SEARCH';
export const EDITOR_SET_SHORTCUT_DROP_DOWN_INDEX = 'EDITOR_SET_SHORTCUT_DROP_DOWN_INDEX';
